/* src/components/Assistant/Discovery.css */

.main-wrapper {
  display: flex;
  flex-direction: row;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 0; /* Sin margen por defecto */
}

/* Aplicar margen izquierdo igual al ancho del Sidebar en pantallas grandes */
@media (min-width: 600px) {
  .main-content {
    margin-left: 240px; /* Ancho del Sidebar */
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filters-container {
  margin-bottom: 30px;
}

.assistant-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.assistant-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top-section {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.assistant-avatar {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border-radius: 50%;
  border: 2px solid #1976d2;
}

.role-tag {
  margin-left: auto;
}

.info-section {
  flex-grow: 1;
}

.assistant-name {
  font-weight: 600;
  margin-bottom: 5px;
  color: #000000; /* Nombre en negro para mayor contraste */
}

.creator-username {
  color: #555555;
  margin-bottom: 10px;
}

.creation-date {
  color: #777777;
  font-size: 0.85rem;
  margin-bottom: 10px;
}

.assistant-description {
  color: #333333;
  font-size: 0.9rem;
  line-height: 1.4;
}

.details-section {
  margin-top: 15px;
}

.metric-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.metric-box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.metric-box svg {
  font-size: 1rem;
}

.metric-box .MuiTypography-caption {
  font-size: 0.85rem;
}

.chat-button-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.chat-button {
  padding: 8px 20px;
  font-size: 0.875rem;
  border-radius: 20px;
  background-color: #000000; /* Negro */
  color: #ffffff;
  transition: background-color 0.3s;
}

/* Aumentar especificidad para sobrescribir estilos de MUI */
.assistant-card .chat-button {
  background-color: #000000 !important; /* Negro */
  color: #ffffff !important;
}

.assistant-card .chat-button:hover {
  background-color: #757575 !important; /* Gris al pasar el cursor */
  color: #ffffff !important;
}

/* Estilos para los Sliders */
.filters-container .MuiSlider-root.slider-grey {
  color: #9e9e9e; /* Gris */
}

.filters-container .MuiTypography-root {
  font-weight: 500;
  margin-bottom: 8px;
}

.slider-label {
  text-align: right;
  color: #9e9e9e; /* Gris */
  font-weight: 500;
}

/* Responsive Adjustments */
@media (max-width: 960px) {
  .assistant-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .top-section {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .assistant-avatar {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .role-tag {
    margin-left: 0;
  }

  .info-section {
    align-items: center;
  }

  .details-section {
    margin-top: 10px;
  }

  .chat-button-container {
    margin-top: 10px;
  }

  .slider-label {
    text-align: center;
  }
}
