/* src/components/Auth/Auth.css */

.auth-container {
  text-align: center;
}

.social-login {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.google-btn,
.facebook-btn {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  text-transform: none; /* Mantener el texto en su forma original */
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
}

.google-btn {
  background-color: #db4437;
}

.google-btn:hover {
  background-color: #c33d2e;
}

.facebook-btn {
  background-color: #3b5998;
}

.facebook-btn:hover {
  background-color: #334d84;
}

button[type='submit'] {
  margin-top: 10px;
  padding: 10px;
  background-color: #4caf50;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #45a049;
}

.feedback {
  margin-top: 10px;
  color: #ff0000;
  font-size: 0.9em;
  text-align: center;
}

.auth-container a {
  color: #1976d2;
  text-decoration: none;
  font-weight: bold;
}

.auth-container a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 600px) {
  .social-login {
    flex-direction: column;
  }

  .google-btn,
  .facebook-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Password Visibility Toggle */
.password-field {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #9e9e9e;
}
