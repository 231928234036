/* src/components/Admin/SidebarCreateAsistente.css */

.sidebar-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar-list .MuiListItem-root {
  width: 100%;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar-list .MuiListItem-root:hover {
  background-color: #f0f0f0;
  color: #000000; /* Color de acento al pasar el cursor */
}

.sidebar-list .MuiListItemIcon-root {
  min-width: unset;
  color: inherit;
  margin-right: 6px; /* Espacio adicional entre avatar e ícono */
}

.sidebar-list .active .MuiListItemIcon-root {
  color: #000000; /* Color de acento para el ítem activo */
}

.footer-link {
  color: #555555;
  text-decoration: none;
  font-size: 12px;
}

.footer-link:hover {
  color: #0084ff;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: #f5f5f5;
}

/* Nuevas clases para separar y uniformizar el espacio entre secciones */
.sidebar-section {
  padding: 10px 10px 20px 10px; /* Padding superior e inferior para separación entre secciones */
}

.section-title {
  margin-bottom: 10px; /* Espacio entre el título y los elementos */
}

.section-divider {
  margin: 0 10px 20px 10px; /* Separación uniforme entre secciones */
}

/* Ajustes para la responsividad y el nuevo ancho */
@media (min-width: 600px) {
  .MuiDrawer-paper {
    width: 240px !important; /* Reducido de 280px a 240px */
  }
}

/* Añadido para asegurar que el contenido principal no desborde horizontalmente */
.MuiDrawer-paper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

/* Estilos para limitar la altura de las listas de asistentes y permitir el scroll vertical */
.assistant-list {
  overflow-y: auto;
  padding-right: 10px; /* Para evitar que el contenido se superponga con el scrollbar */
}

/* Especificar diferentes alturas máximas para móvil y escritorio */
.assistant-list-mobile {
  max-height: 180px; /* Aproximadamente 3 asistentes */
}

.assistant-list-desktop {
  max-height: 220px; /* Aproximadamente 4 asistentes */
}

/* Estilos personalizados para los scrollbars (opcional) */
/* Para navegadores basados en Webkit */
.assistant-list::-webkit-scrollbar {
  width: 6px;
}

.assistant-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.assistant-list::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.assistant-list::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* **Nuevos Estilos para Separación y Ajuste de Texto** */

/* Agregar margen entre el avatar y el nombre del asistente */
.sidebar-list .MuiListItemText-root {
  margin-left: 4px; /* Ajusta este valor según necesites más o menos espacio */
}

/* Permitir que el texto del asistente se divida en dos líneas si excede el ancho */
.sidebar-list .MuiListItemText-primary {
  white-space: normal; /* Permite el ajuste de líneas */
  word-break: break-word; /* Rompe las palabras si es necesario */
  line-height: 1.2; /* Espacio entre líneas */
}

/* Opcional: Ajustar la altura de las filas para mayor espacio */
.sidebar-list .MuiListItemButton-root {
  padding-top: 6px;
  padding-bottom: 6px;
}

/* Ajuste para evitar que el AppBar cubra el contenido en móvil */
@media (max-width: 600px) {
  .MuiDrawer-root {
    top: 64px; /* Altura del AppBar */
  }
}

/* Ocultar la scrollbar en el Sidebar */
/* Estilos para el contenedor scrollable */
.sidebar-scrollable {
  overflow-y: auto;
}

/* Ocultar scrollbar para navegadores basados en Webkit */
.sidebar-scrollable::-webkit-scrollbar {
  display: none;
}

/* Ocultar scrollbar para Firefox */
.sidebar-scrollable {
  -ms-overflow-style: none; /* IE y Edge */
  scrollbar-width: none; /* Firefox */
}
