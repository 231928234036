/* src/components/Assistant/Assistant.css */

/* Animations */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

/* Loader for video and audio */
.loader {
  border: 8px solid #f3f3f3; 
  border-top: 8px solid #3490dc; 
  border-radius: 50%;
  width: 60px; 
  height: 60px; 
  animation: spin 1s linear infinite;
}

/* Loader for typing indicator */
.loader-dots {
  display: flex;
  gap: 8px; 
}

.loader-dots div {
  width: 12px; 
  height: 12px; 
  background-color: #3490dc;
  border-radius: 50%;
  animation: bounce 1.4s infinite both;
}

.loader-dots div:nth-child(1) {
  animation-delay: -0.32s;
}

.loader-dots div:nth-child(2) {
  animation-delay: -0.16s;
}

.messages-container {
  border: 2px solid blue;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

/* Waves Wrapper */
.avatar-waves-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Waves Effect */
.avatar-waves {
  position: absolute;
  width: 150%; /* Ensure waves expand beyond the avatar */
  height: 150%;
  border-radius: 50%;
}

.avatar-waves::before,
.avatar-waves::after {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 50%;
  animation: wave 5s infinite;
  opacity: 0.6;
  z-index: 1;
}

.avatar-waves.disponible::before,
.avatar-waves.disponible::after {
  background: radial-gradient(circle, rgba(0, 0, 255, 0.4), transparent);
}

.avatar-waves.escribiendo::before,
.avatar-waves.escribiendo::after {
  background: radial-gradient(circle, rgba(54, 249, 54, 0.4), transparent);
  animation: pulse 2s infinite;
}

.avatar-waves.desconectado::before,
.avatar-waves.desconectado::after {
  background: radial-gradient(circle, rgba(128, 128, 128, 0.4), transparent);
}

@keyframes wave {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(2.5); /* Larger waves */
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.6;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.6;
  }
}

/* Animation for assistant messages */
.assistant-message {
  animation: slideUp 0.3s ease-out;
}

/* Menus */
.MuiMenu-paper {
  border-radius: 12px; 
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

.MuiMenuItem-root {
  display: flex;
  align-items: center;
}

.MuiMenuItem-root .MuiListItemIcon-root {
  min-width: 20px; 
  color: #555555;
}

.MuiMenuItem-root:hover {
  background-color: #e0e0e0; 
}

/* Updated avatar-waves size for desktop and mobile */
@media (min-width: 300px) {
  .avatar-waves {
    width: 160px; 
    height: 160px; 
  }
}

@media (max-width: 300px) {
  .avatar-waves {
    width: 160px; 
    height: 160px; 
  }
}

/* Camera active state */
.camera-active {
  background-color: #36f936 !important; /* Bright green */
  color: #fff !important; /* White icon color */
}

/* Mic active state for WhatsApp-like look */
.mic-active {
  background-color: #36f936  !important; /* Bright green */
  color: #fff !important;
}

/* Audio review overlay */
.audio-review-overlay {
  position: fixed;
  bottom: 100px; 
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
  padding: 20px;
  z-index: 2000;
  text-align: center;
  width: 90%;
  max-width: 300px;
}

/* src/components/Assistant/Assistant.css */

input[type="file"] {
  display: none;
}

/* Adjust spacing for icons in text fields */
.MuiInputAdornment-root {
  padding: 2px; /* Adjust padding for smaller icons */
}