/* src/components/Home.css */

/* Elimina o minimiza el uso de clases CSS externas para evitar conflictos con Material-UI */

/* Estilos generales para las tarjetas */
.assistant-card {
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Evitar superposiciones no deseadas */
.hover-gradient {
  /* Asegurado en Home.js que el z-index es correcto */
}

/* Media Queries para mejorar la responsividad en móvil */
@media (max-width: 600px) {
  /* Ajustes para CharacterCard */
  .assistant-card {
    padding: 8px !important;
  }

  .media-image {
    height: 100px;
  }

  .media-placeholder {
    height: 100px;
  }

  /* Ajustar el tamaño de las fuentes */
  .assistant-card h6 {
    font-size: 1rem !important;
  }

  .assistant-card body2 {
    font-size: 0.875rem !important;
  }

  .assistant-card caption {
    font-size: 0.75rem !important;
  }

  /* Ajustar los botones */
  .assistant-card button {
    padding: 8px 16px !important;
    font-size: 0.75rem !important;
  }

  /* Ajustar el tamaño de los iconos */
  .mui-icon-button svg {
    width: 24px;
    height: 24px;
  }

  
  /* Ajustar el tamaño de los botones en ActionButtons */
  .action-buttons button {
    min-width: 150px !important;
    padding: 10px 16px !important;
    font-size: 0.875rem !important;
  }

  /* Ajustar Slider (si aún se utiliza react-slick en alguna parte) */
  .slick-slider {
    width: 100% !important;
  }

  /* Evitar que los elementos del Slider tengan ancho infinito */
  .slick-slide > div {
    width: auto !important;
  }
}

/* Responsive Adjustments */
@media (max-width: 960px) {
  .assistant-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .top-section {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .assistant-avatar {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .role-tag {
    margin-left: 0;
  }

  .info-section {
    align-items: center;
  }

  .details-section {
    margin-top: 10px;
  }

  .chat-button-container {
    margin-top: 10px;
  }

  .slider-label {
    text-align: center;
  }
}

/* Prevenir Desbordamiento Global */
body, html, #root {
  width: 100%;
  overflow-x: hidden; /* Prevenir desplazamiento horizontal */
}