/* src/components/Sidebar.css */

.sidebar {
  width: 300px;
  transition: width 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  border-right: 1px solid #ccc;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar-header {
  position: relative;
  padding: 10px;
}

.collapse-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.create-button,
.discover-button,
.premium-button {
  border-radius: 15px;
  margin-bottom: 10px;
}

.search-field {
  margin-bottom: 10px;
  border-radius: 15px;
  background-color: #f0f0f0;
}

.premium-button {
  background-color: #ffeb3b;
  color: #000;
}

.user-info {
  display: flex;
  align-items: center;
  padding: 10px;
}

.footer-links {
  padding: 10px;
}
