/* src/components/Assistant/MisAsistentes.css */

/* Contenedor Principal */
.main-wrapper {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}

/* Contenido Principal */
.main-content {
  flex-grow: 1;
  padding: 24px;
  width: calc(100% - 280px); /* 280px es el ancho del Drawer */
  overflow-y: auto;
}

/* Header Container */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

@media (min-width: 600px) {
  .header-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* Título */
.title {
  text-align: center;
  margin-bottom: 16px;
  color: #333333;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

@media (min-width: 600px) {
  .title {
    text-align: left;
    margin-bottom: 0;
  }
}

/* Botón Agregar Asistente */
.add-assistant-button {
  margin-top: 16px;
  background-color: #4caf50;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  padding: 10px 20px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-assistant-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* Contenedor de Filtros */
.filters-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  margin-bottom: 24px;
  padding: 0 16px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 600px) {
  .filters-container {
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
  }
}

/* Barra de Búsqueda */
.search-bar {
  flex: 1;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .search-bar {
    width: 100%;
  }
}

/* Control de Ordenamiento */
.sort-control {
  min-width: 200px;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .sort-control {
    width: 100%;
  }
}

/* Filtro por Categoría */
.category-filter {
  min-width: 200px;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .category-filter {
    width: 100%;
  }
}

/* Ajustes de Selects */
.MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 8px;
}

/* Ajustes de Labels */
.MuiInputLabel-root {
  font-size: 1rem;
}

/* Ajustes de Select */
.MuiSelect-select {
  font-size: 1rem;
}

/* Icono en la Barra de Búsqueda */
.MuiInputAdornment-root {
  margin-right: 8px;
}

/* Tarjeta de Asistente */
.assistant-card {
  background-color: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.assistant-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  background-color: #f0f4f8;
}

/* Contenido de la Tarjeta */
.card-content {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

/* Sección Superior: Avatar, Icono, Categoría, Nombre, Botones */
.top-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  position: relative;
  gap: 12px;
}

@media (min-width: 600px) {
  .top-section {
    flex-direction: row;
    align-items: center;
  }
}

/* Avatar */
.assistant-avatar {
  width: 64px;
  height: 64px;
  border: 2px solid #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .assistant-avatar {
    width: 48px;
    height: 48px;
  }
}

/* Chip de Categoría */
.category-chip {
  margin-right: 8px;
  border-color: transparent;
  padding: 4px 8px;
  font-size: 0.75rem;
}

/* Nombre del Asistente */
.assistant-name {
  font-weight: 600;
  color: #333333;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  flex-grow: 1;
  font-size: 1.1rem;
}

@media (max-width: 600px) {
  .assistant-name {
    font-size: 1rem;
  }
}

/* Icono de Tipo de Asistente */
.assistant-type-icon {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

/* Botones de Acción */
.action-buttons {
  display: flex;
  gap: 8px;
}

@media (max-width: 600px) {
  .action-buttons {
    flex-direction: column;
    width: 100%;
  }
}

/* Botones Editar y Borrar */
.edit-button,
.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  border-radius: 8px;
  font-weight: 600;
  padding: 8px 16px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

@media (max-width: 600px) {
  .edit-button,
  .delete-button {
    padding: 6px 12px;
    font-size: 0.75rem;
  }

  /* Ocultar el texto de los botones en móviles */
  .edit-button .button-text,
  .delete-button .button-text {
    display: none;
  }
}

.edit-button:hover,
.delete-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

/* Fecha de Creación */
.creation-date {
  margin-top: 4px;
  color: #777777;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .creation-date {
    font-size: 0.875rem;
  }
}

/* Descripción del Asistente */
.description-container {
  padding: 12px 16px;
  color: #555555;
  font-size: 1rem;
  border-top: 1px solid #e0e0e0;
}

@media (max-width: 600px) {
  .description-container {
    padding: 8px 12px;
    font-size: 0.875rem;
  }
}

/* Detalles del Asistente */
.details-container {
  padding: 16px;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  transition: background-color 0.3s ease;
}

.assistant-card:hover .details-container {
  background-color: #e0f7fa;
}

@media (max-width: 600px) {
  .details-container {
    padding: 12px;
  }
}

/* Contenedor de Estadísticas en una Línea */
.metric-item {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .metric-item {
    font-size: 0.75rem;
  }
}

/* Estado de Carga */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Sin Resultados */
.no-results {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  color: #9e9e9e;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Tooltip Personalizado */
.MuiTooltip-tooltip {
  font-size: 0.875rem;
  border-radius: 8px;
}

/* Responsividad Mejorada */
@media (max-width: 600px) {
  .assistant-avatar {
    width: 48px;
    height: 48px;
  }

  .category-chip {
    font-size: 0.65rem; /* Tamaño aún más pequeño para móviles */
    padding: 2px 6px;
  }

  .description-container {
    padding: 8px 12px;
    font-size: 0.875rem;
  }

  .details-container {
    padding: 12px;
    gap: 2px;
  }

  .edit-button,
  .delete-button {
    padding: 6px 12px;
    font-size: 0.75rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .add-assistant-button {
    width: 100%;
    margin-top: 16px;
  }

  .filters-container {
    padding: 0 12px;
  }

  .action-buttons {
    flex-direction: row;
    width: 100%;
  }
}
