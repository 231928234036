/* Animación para la aparición de los iniciadores de conversación */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Contenedor principal del formulario */
.form-container {
  /* Los estilos principales están manejados a través de MUI sx props */
}

/* Imagen de Perfil del Asistente */
.image-container {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 10px auto;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f9f9f9;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.image-container:hover {
  transform: scale(1.05);
}

.image-container .MuiIconButton-root {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 1px solid #ffffff;
  padding: 2px;
  transition: background-color 0.3s ease-in-out;
}

.image-container .MuiIconButton-root:hover {
  background-color: #ffffff;
}

/* Campo de Texto: Nombre del Asistente */
.text-field {
  /* Estilos específicos para cada campo están manejados en MUI sx props */
}

.text-field input::placeholder {
  font-style: italic;
  color: #888888;
}

/* Campo de Texto: Descripción del Asistente */
.description-field {
  /* Estilos específicos manejados en MUI sx props */
}

/* Campo de Instrucciones */
.instructions-field {
  /* Estilos específicos manejados en MUI sx props */
}

/* Sección de Iniciadores de Conversaciones */
.conversation-starters {
  /* Estilos específicos manejados en MUI sx props */
}

.conversation-starter-input {
  width: 90%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #333333;
  transition: all 0.3s ease-in-out;
}

.conversation-starter-input:focus-within {
  border: 1px solid #388E3C;
}

/* Botón de Eliminación (X) en Iniciadores de Conversación */
.remove-conversation-button {
  margin-left: 10px;
  background-color: #333333; /* Gris oscuro */
  color: #ffffff;
  border: 1px solid #222222;
  border-radius: 4px;
  padding: 0;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.remove-conversation-button:hover {
  background-color: #555555;
}

/* Botón de Añadir Conversación */
.add-conversation-button {
  background-color: #333333; /* Gris oscuro */
  color: #ffffff;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.add-conversation-button:hover {
  background-color: #555555;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Contadores de Caracteres */
.character-counter {
  font-size: 12px;
  color: #888888;
  transition: color 0.3s ease-in-out;
}

.character-counter.over-limit {
  color: #ff4d4f;
}

/* Botón de Envío */
.submit-button {
  display: flex;
  justify-content: center; /* Centrar el botón */
  margin-top: 20px;
}

.submit-button .MuiButton-root {
  /* Estilos específicos manejados en MUI sx props */
}

/* Feedback de Error */
.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .form-container {
    padding: 16px;
    margin: 20px;
    width: 100%;
  }

  .image-container {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .submit-button .MuiButton-root {
    width: 100%;
  }

  .text-field {
    width: 100%;
  }

  .conversation-starter-input {
    width: 100%;
  }

  .add-conversation-button {
    width: 100%;
    margin-top: 10px;
  }
}
